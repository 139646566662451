import {useCallback, useEffect} from 'react';

import {
	REQUEST_DIALOG_CURRENCY_TITLE,
	REQUEST_DIALOG_WORKSPACE_BUTTON_TEXT,
} from '@/components/common/dialogs/request-dialog/parts';
import {RequestDialog} from '@/components/common/dialogs/request-dialog/RequestDialog';
import {
	SUCCESS_DIALOG_CONNECTION_BUTTON_TEXT,
	SUCCESS_DIALOG_CONNECTION_DESCRIPTION,
	SUCCESS_DIALOG_CONNECTION_TITLE,
} from '@/components/common/dialogs/success-dialog/parts/biz';
import {SuccessDialog} from '@/components/common/dialogs/success-dialog/SuccessDialog';
import {QUERY_PARAM_IDS} from '@/constants';
import {ServiceLandingName} from '@/services/api/interfaces/request';
import {ILightboxService} from '@/services/lightbox/ILightboxService';
import {
	GA4EventId,
	MetricsCategory,
	TMRReachGoalId,
	UAEventType,
	YAReachGoalId,
} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {addGetParam, removeGetParams} from '@/utilites/manipulateWithUrl';

import {useHasGetParam} from './use-has-get-param';
import {useInjection} from './use-injection';

export const COUNTRIES = 'countries';
const AMO_TAG_FOS_SNG = 'fos_sng';

const onCloseAnotherCurrencyLightbox = () => {
	removeGetParams([QUERY_PARAM_IDS.MODAL_ID]);
};

const useSuccessDialogWithMetrics = (lightboxService: ILightboxService, metricsService: IMetricsService) => {
	return useCallback(() => {
		lightboxService.openReadonly(SuccessDialog, {
			title: SUCCESS_DIALOG_CONNECTION_TITLE,
			description: SUCCESS_DIALOG_CONNECTION_DESCRIPTION,
			buttonText: SUCCESS_DIALOG_CONNECTION_BUTTON_TEXT,
		});
		metricsService.sendEvent({
			yaGoals: [YAReachGoalId.OnPremiseMailFormSubmitted],
			tmrGoals: [TMRReachGoalId.OnPremiseMailFormSubmitted],
			ga4Events: [GA4EventId.Submit],
			uaEvents: [
				{
					type: UAEventType.MainEvent,
					payload: {
						category: MetricsCategory.Enterprise,
						action: 'Submit',
					},
				},
			],
		});
	}, [lightboxService, metricsService]);
};

const AnotherCurrencyLightboxContentProps = {
	landingName: ServiceLandingName.Workspace,
	additionalTags: AMO_TAG_FOS_SNG,
	noPhoneMask: true,
	title: REQUEST_DIALOG_CURRENCY_TITLE,
	buttonText: REQUEST_DIALOG_WORKSPACE_BUTTON_TEXT,
	onClose: onCloseAnotherCurrencyLightbox,
	isSngCurrency: true,
};

export function useOpenCountriesLightboxOnInitApp() {
	const [lightboxService] = useInjection(ILightboxService);
	const [metricsService] = useInjection(IMetricsService);

	const handleSubmitSuccessConnectionDialog = useSuccessDialogWithMetrics(lightboxService, metricsService);
	const shouldShow = useHasGetParam(QUERY_PARAM_IDS.MODAL_ID, COUNTRIES);

	useEffect(() => {
		if (shouldShow) {
			lightboxService.openReadonlyAsync(RequestDialog, {
				...AnotherCurrencyLightboxContentProps,
				onSubmitSuccess: handleSubmitSuccessConnectionDialog,
			});
		}
	}, [handleSubmitSuccessConnectionDialog, lightboxService, shouldShow]);
}

export function useOpenCountriesLightboxByClick() {
	const [lightboxService] = useInjection(ILightboxService);
	const [metricsService] = useInjection(IMetricsService);

	const handleSubmitSuccessConnectionDialog = useSuccessDialogWithMetrics(lightboxService, metricsService);

	return useCallback(() => {
		addGetParam(QUERY_PARAM_IDS.MODAL_ID, COUNTRIES);
		lightboxService.openReadonly(RequestDialog, {
			...AnotherCurrencyLightboxContentProps,
			onSubmitSuccess: handleSubmitSuccessConnectionDialog,
		});
	}, [handleSubmitSuccessConnectionDialog, lightboxService]);
}
