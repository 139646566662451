export const addGetParam = (param: string, value: string) => {
	const currentUrl = window.location.href;
	// eslint-disable-next-line compat/compat
	const url = new URL(currentUrl);
	url.searchParams.set(param, value);
	const newUrl = url.toString();
	window.history.replaceState(null, '', newUrl);
};

export const removeGetParams = (paramsToDelete: string[]) => {
	const currentUrl = window.location.href;
	// eslint-disable-next-line compat/compat
	const url = new URL(currentUrl);

	const params = url.searchParams;

	paramsToDelete.forEach((param) => {
		params.delete(param);
	});

	const newUrl = url.pathname + (params.toString() ? `?${params.toString()}` : '');

	history.replaceState(null, '', newUrl);
};
